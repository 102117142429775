import React,{useState,useEffect} from 'react';
import Axios from 'axios'
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle,Typography } from '@material-ui/core';
import 'bootstrap/dist/css/bootstrap.css';

const useStyles = makeStyles({
    root: {
        maxWidth: 345,
    },
    media: {
        height: 140,
    },
});

const seePdf = () => {
    let id = document.getElementById("idevento").value;

    var action="getFile";
    Axios.post("https://api.rimond.tecnohub.eu/api/server/server.php",{action,id}).then((data)=>{
        if(data.data)
        window.open(data.data);
        else
        alert('No file');
    });
}

const seeSignedPdf = () => {
    let id = document.getElementById("idevento").value;

    var action="getSignedFile";
    Axios.post("https://api.rimond.tecnohub.eu/api/server/server.php",{action,id},{
        responseType: 'blob',
    } ).then((data)=>{
        console.log(data);
        if(data.data)
        {
            var file = new Blob([data.data], { type: 'application/pdf' });
            var url = URL.createObjectURL(file);
            window.open(url, '_blank');
        }
        else
        alert('No file');
    });
}


const deletePdf = () => {
    let id = document.getElementById("idevento").value;

    var action="deleteFile";
    Axios.post("https://api.rimond.tecnohub.eu/api/server/server.php",{action,id}).then((data)=>{
        if(data.data.success=='ok')
        {
            alert('Delete success');
        }
        else
        {
            alert('Delete error');
        }
    });
}

const deleteEvent = (props) => {
    let id = document.getElementById("idevento").value;

    var action="deleteEvent";
    Axios.post("https://api.rimond.tecnohub.eu/api/server/server.php",{action,id}).then((data)=>{
        if(data.data.success=='ok')
        {
            console.log('Delete success');
        }
        else
        {
            console.log('Delete error');
        }
    });

    props.onDelete.current.deleteInTimeline(id);
    props.onClose();

}

export default function InfoWindow(props) {

    const { onOpenViewpoint, getState, ...rest } = props;

    return (
      <Dialog maxWidth='md' fullWidth disableBackdropClick {...rest}>
          <DialogTitle>Info Event</DialogTitle>
          <DialogContent>
                <Box
                  height={500}
                  display='flex'
                  alignItems="center"
                  justifyContent="center"
                  border={"solid 4px"}
                  borderColor={"#FF0000"}
                  borderRadius={20}
                >
                <Typography>
                <form>
                <input type="hidden" id="idevento"/>
                <div className='row g-2 align-items-center form-control-sm'> <div className="col-auto"> EVENT NAME: </div>  <div className="col-auto">  <input className="form-control form-control-sm" type="text" id="nomeevento"/> </div></div> 
                <div className='row g-2 align-items-center form-control-sm'> <div className="col-auto">EVENT TYPE: </div> <div className="col-auto"> <select className="form-select form-select-sm" id="tipoevento" name="tipoevento" ><option value=""></option><option value="Bassa urgenza">Bassa urgenza</option><option value="Priorità alta">Priorità alta</option><option value="Priorità media">Priorità media</option></select></div></div> 
                <div className='row g-2 align-items-center form-control-sm'> <div className="col-auto"> EVENT DESCRIPTION: </div> <div className="col-auto"> <input className="form-control form-control-sm" id="descrizioneevento" type="text"/></div></div> 
                <div className='row g-2 align-items-center form-control-sm'> <div className="col-auto"> EVENT WORKER: </div> <div className="col-auto"><select className="form-select form-select-sm" id="manuevento" name="manuevento"><option value=""></option><option value="Benedetto Olivieri">Benedetto Olivieri</option></select></div></div> 
                <div className='row g-2 align-items-center form-control-sm'> <div className="col-auto"> EVENT START DATE: </div> <div className="col-auto"> <input className="form-control form-control-sm" type="date" id="inizioevento"/></div></div>
                <div className='row g-2 align-items-center form-control-sm'> <div className="col-auto"> EVENT END DATE: </div> <div className="col-auto"> <input className="form-control form-control-sm" type="date" id="fineevento"/></div></div> 
                <div className='row g-2 align-items-center form-control-sm'> <div className="col-auto"> EVENT STATUS: </div> <div className="col-auto"> <select className="form-select form-select-sm" id="statoevento" name="statoevento" ><option value=""></option><option value="Nessun incaricato">Nessun incaricato</option><option value="Proposta da approvare">Proposta da approvare</option><option value="Da assegnare al manutentore">Da assegnare al manutentore</option><option value="Inviato al manutentore">Inviato al manutentore</option><option value="In carico al manutentore">In carico al manutentore</option><option value="In lavorazione">In lavorazione</option><option value="Sospeso">Sospeso</option><option value="Chiuso, da convalidare">Chiuso, da convalidare</option><option value="Convalida negata">Convalida negata</option><option value="Convalidato">Convalidato</option><option value="Annullato">Annullato</option></select></div></div> 
                <div className='row g-2 align-items-center form-control-sm'> <div className="col-auto"> EVENT FILE: </div> <div className="col-auto"><input className="form-control form-control-sm btn btn-outline-primary btn-sm" type='button' id='fileevento' value='open' onClick={seePdf}/><input className="form-control form-control-sm btn btn-outline-primary btn-sm" type='button' id='deletefileevento' value='delete' onClick={deletePdf}/><input className="form-control form-control-sm btn btn-outline-primary btn-sm" type='button' id='fileeventofirmato' value='open signed' onClick={seeSignedPdf}/></div></div> 
                <div className='row g-2 align-items-center form-control-sm'> <div className="col-auto"> DELETE EVENT: </div> <div className="col-auto"><input className="form-control form-control-sm btn btn-outline-primary btn-sm" type='button' id='deleteevento' value='delete' onClick={ event => {deleteEvent(props);}}/></div></div> 
                </form> 
                </Typography>
                </Box>
          </DialogContent>
          <DialogActions>
          <Button onClick={props.onSubmit}>
                  Submit
              </Button>
              <Button onClick={props.onClose}>
                  Close
              </Button>
          </DialogActions>
      </Dialog>
    );
}


function TopicCard(props){

    const classes = useStyles();

    const { topic, onOpenViewpoint } = props;

    const defaultViewpoint = topic?.markup?.viewpoints?.length > 0 ? topic.markup.viewpoints[0] : null;

    const [defaultViewpointUrl, setDefaultViewpointUrl] = useState("");

    const handleClick = () => {
        if(topic.viewpoints.length > 0) {
            onOpenViewpoint(topic.viewpoints[0]);
        }
    }

    useEffect(() => {
        const getViewpoint = async () => {
            if(defaultViewpoint){
                const data = await topic.getViewpointSnapshot(defaultViewpoint);
                const url = window.URL.createObjectURL(data);
                setDefaultViewpointUrl(url);
            }
        }
        getViewpoint()
    }, [defaultViewpoint, topic]);

    return (
      <Card className={classes.root}>
          <CardActionArea onClick={handleClick}>
              <CardMedia
                className={classes.media}
                image={defaultViewpointUrl}
                title={topic.markup.topic.title}
              />
              <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                      {topic.markup.topic.title}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                      {topic.markup.topic.description}
                  </Typography>
              </CardContent>
          </CardActionArea>
          {/*<CardActions>
              <Button size="small" color="primary">
                  Open
              </Button>
              <Button size="small" color="primary">
                  Learn More
              </Button>
          </CardActions>*/}
      </Card>
    )
}