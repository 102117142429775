import React,{useState,useEffect} from 'react';
import Axios from 'axios'
import { DataSet, Timeline } from "vis-timeline/standalone";
import "vis-timeline/styles/vis-timeline-graph2d.css";
import 'bootstrap/dist/css/bootstrap.css';

class TimelineComponent extends React.Component {
    
    state = {
        proptimeline: null,
        propevents: null
    };

    constructor(props) {
        super(props);
    }
    
    componentDidMount(){
        
        const apriInfo = this.props;

        // DOM element where the Timeline will be attached
        const container = document.getElementById("root");

        var events = [];

        // Create a DataSet (allows two way data-binding)
        var action="getAllEvents";
        Axios.post("https://api.rimond.tecnohub.eu/api/server/server.php",{action}).then((data)=>{
            
            if(data.data!=null){
                data.data.map((info) => {
                    let datai = new Date(info.inizio);
                    let mesei = datai.getMonth()+1;
                    let annoi = datai.getFullYear();
                    let giornoi = datai.getDate();
                    datai = annoi + '-' + mesei + '-' + giornoi;
                    let dataf = new Date(info.fine);
                    let mesef = dataf.getMonth()+1;
                    let annof = dataf.getFullYear();
                    let giornof = dataf.getDate();
                    dataf = annof + '-' + mesef + '-' + giornof;
                    if(dataf == datai)
                    {
                        if(info.stato == 'Nessun incaricato')
                        {
                            events.push({id: parseInt(info.id), content: info.name, start: datai, style: "background-color: red;" });
                        }
                        else if(info.stato == "Proposta da approvare")
                        {
                            events.push({id: parseInt(info.id), content: info.name, start: datai, style: "background-color: blue;" });
                        }
                        else if(info.stato == "Da assegnare al manutentore")
                        {
                            events.push({id: parseInt(info.id), content: info.name, start: datai, style: "background-color: orange;" });
                        }
                        else if(info.stato == "Inviato al manutentore")
                        {
                            events.push({id: parseInt(info.id), content: info.name, start: datai, style: "background-color: pink;" });
                        }
                        else if(info.stato == "In carico al manutentore")
                        {
                            events.push({id: parseInt(info.id), content: info.name, start: datai, style: "background-color: pink;" });
                        }
                        else if(info.stato == "In lavorazione")
                        {
                            events.push({id: parseInt(info.id), content: info.name, start: datai, style: "background-color: pink;" });
                        }
                        else if(info.stato == "Sospeso")
                        {
                            events.push({id: parseInt(info.id), content: info.name, start: datai, style: "background-color: orange;" });
                        }
                        else if(info.stato == "Chiuso, da convalidare")
                        {
                            events.push({id: parseInt(info.id), content: info.name, start: datai, style: "background-color: green;" });
                        }
                        else if(info.stato == "Convalida negata")
                        {
                            events.push({id: parseInt(info.id), content: info.name, start: datai, style: "background-color: red;" });
                        }else if(info.stato == "Convalidato")
                        {
                            events.push({id: parseInt(info.id), content: info.name, start: datai, style: "background-color: darkgray;" });
                        }
                        else if(info.stato == "Annullato")
                        {
                            events.push({id: parseInt(info.id), content: info.name, start: datai, style: "background-color: darkgray;" });
                        }
                        
                        
                    }
                    else{
                        if(info.stato == 'Nessun incaricato')
                        {
                            events.push({id: parseInt(info.id), content: info.name, start: datai, end: dataf, type: "range", style: "background-color: red;" });
                        }
                        else if(info.stato == "Proposta da approvare")
                        {
                            events.push({id: parseInt(info.id), content: info.name, start: datai, end: dataf, type: "range", style: "background-color: blue;" });
                        }
                        else if(info.stato == "Da assegnare al manutentore")
                        {
                            events.push({id: parseInt(info.id), content: info.name, start: datai, end: dataf, type: "range", style: "background-color: orange;" });
                        }
                        else if(info.stato == "Inviato al manutentore")
                        {
                            events.push({id: parseInt(info.id), content: info.name, start: datai, end: dataf, type: "range", style: "background-color: pink;" });
                        }
                        else if(info.stato == "In carico al manutentore")
                        {
                            events.push({id: parseInt(info.id), content: info.name, start: datai, end: dataf, type: "range", style: "background-color: pink;" });
                        }
                        else if(info.stato == "In lavorazione")
                        {
                            events.push({id: parseInt(info.id), content: info.name, start: datai, end: dataf, type: "range", style: "background-color: pink;" });
                        }
                        else if(info.stato == "Sospeso")
                        {
                            events.push({id: parseInt(info.id), content: info.name, start: datai, end: dataf, type: "range", style: "background-color: orange;" });
                        }
                        else if(info.stato == "Chiuso, da convalidare")
                        {
                            events.push({id: parseInt(info.id), content: info.name, start: datai, end: dataf, type: "range", style: "background-color: green;" });
                        }
                        else if(info.stato == "Convalida negata")
                        {
                            events.push({id: parseInt(info.id), content: info.name, start: datai, end: dataf, type: "range", style: "background-color: red;" });
                        }
                        else if(info.stato == "Convalidato")
                        {
                            events.push({id: parseInt(info.id), content: info.name, start: datai, end: dataf, type: "range", style: "background-color: darkgray;" });
                        }
                        else if(info.stato == "Annullato")
                        {
                            events.push({id: parseInt(info.id), content: info.name, start: datai, end: dataf, type: "range", style: "background-color: darkgray;" });
                        }
                    }
                    
                    const items = new DataSet(events);            
                });
            }
            
            // Configuration for the Timeline
            const options = {
                width: '100%',
                height: '155px',
                showCurrentTime: true
            };
    
            // Create a Timeline
            const timeline = new Timeline(container, new DataSet(events), options);

            this.setState({
                ...this.state,
                proptimeline: timeline,
                propevents: events
            });

            let timer

            timeline.on("click", function (properties) {
                if (properties.event.detail === 1) {
                    timer = setTimeout(() => {
                        apriInfo.onSingleClick(properties.item);
                      }, 450);
                  }
              });

            timeline.on("doubleClick", function (properties) {
                clearTimeout(timer);
                apriInfo.onOpen(properties.item);
              });

            });

    }


    updateTimeline(info)
    {
        const timeline = this.state.proptimeline;
        var events = this.state.propevents;

        let datai = new Date(info.start);
        let mesei = datai.getMonth()+1;
        let annoi = datai.getFullYear();
        let giornoi = datai.getDate();
        datai = annoi + '-' + mesei + '-' + giornoi;
        let dataf = new Date(info.end);
        let mesef = dataf.getMonth()+1;
        let annof = dataf.getFullYear();
        let giornof = dataf.getDate();
        dataf = annof + '-' + mesef + '-' + giornof;

        if(dataf == datai)
        {
            if(info.stato == 'Nessun incaricato')
            {
                events.push({id: parseInt(info.id), content: info.name, start: datai, style: "background-color: red;" });
            }
            else if(info.stato == "Proposta da approvare")
            {
                events.push({id: parseInt(info.id), content: info.name, start: datai, style: "background-color: blue;" });
            }
            else if(info.stato == "Da assegnare al manutentore")
            {
                events.push({id: parseInt(info.id), content: info.name, start: datai, style: "background-color: orange;" });
            }
            else if(info.stato == "Inviato al manutentore")
            {
                events.push({id: parseInt(info.id), content: info.name, start: datai, style: "background-color: pink;" });
            }
            else if(info.stato == "In carico al manutentore")
            {
                events.push({id: parseInt(info.id), content: info.name, start: datai, style: "background-color: pink;" });
            }
            else if(info.stato == "In lavorazione")
            {
                events.push({id: parseInt(info.id), content: info.name, start: datai, style: "background-color: pink;" });
            }
            else if(info.stato == "Sospeso")
            {
                events.push({id: parseInt(info.id), content: info.name, start: datai, style: "background-color: orange;" });
            }
            else if(info.stato == "Chiuso, da convalidare")
            {
                events.push({id: parseInt(info.id), content: info.name, start: datai, style: "background-color: green;" });
            }
            else if(info.stato == "Convalida negata")
            {
                events.push({id: parseInt(info.id), content: info.name, start: datai, style: "background-color: red;" });
            }
            else if(info.stato == "Convalidato")
            {
                events.push({id: parseInt(info.id), content: info.name, start: datai, style: "background-color: darkgray;" });
            }
            else if(info.stato == "Annullato")
            {
                events.push({id: parseInt(info.id), content: info.name, start: datai, style: "background-color: darkgray;" });
            }
        }
        else{
            if(info.stato == 'Nessun incaricato')
            {
                events.push({id: parseInt(info.id), content: info.name, start: datai, end: dataf, type: "range", style: "background-color: red;" });
            }
            else if(info.stato == "Proposta da approvare")
            {
                events.push({id: parseInt(info.id), content: info.name, start: datai, end: dataf, type: "range", style: "background-color: blue;" });
            }
            else if(info.stato == "Da assegnare al manutentore")
            {
                events.push({id: parseInt(info.id), content: info.name, start: datai, end: dataf, type: "range", style: "background-color: orange;" });
            }
            else if(info.stato == "Inviato al manutentore")
            {
                events.push({id: parseInt(info.id), content: info.name, start: datai, end: dataf, type: "range", style: "background-color: pink;" });
            }
            else if(info.stato == "In carico al manutentore")
            {
                events.push({id: parseInt(info.id), content: info.name, start: datai, end: dataf, type: "range", style: "background-color: pink;" });
            }
            else if(info.stato == "In lavorazione")
            {
                events.push({id: parseInt(info.id), content: info.name, start: datai, end: dataf, type: "range", style: "background-color: pink;" });
            }
            else if(info.stato == "Sospeso")
            {
                events.push({id: parseInt(info.id), content: info.name, start: datai, end: dataf, type: "range", style: "background-color: orange;" });
            }
            else if(info.stato == "Chiuso, da convalidare")
            {
                events.push({id: parseInt(info.id), content: info.name, start: datai, end: dataf, type: "range", style: "background-color: green;" });
            }
            else if(info.stato == "Convalida negata")
            {
                events.push({id: parseInt(info.id), content: info.name, start: datai, end: dataf, type: "range", style: "background-color: red;" });
            }
            else if(info.stato == "Convalidato")
            {
                events.push({id: parseInt(info.id), content: info.name, start: datai, end: dataf, type: "range", style: "background-color: darkgray;" });
            }
            else if(info.stato == "Annullato")
            {
                events.push({id: parseInt(info.id), content: info.name, start: datai, end: dataf, type: "range", style: "background-color: darkgray;" });
            }
        }

        timeline.setItems(new DataSet(events));
        timeline.redraw();

        this.setState({
            ...this.state,
            proptimeline: timeline,
            propevents: events
        });
    }

    deleteInTimeline(id)
    {
        const timeline = this.state.proptimeline;
        var events = this.state.propevents;
        var newevents = [];
        for(var i=0; i<events.length; i++)
        {
            if(events[i].id != parseInt(id))
            {
                newevents.push(events[i]);
            }
        }

        timeline.setItems(new DataSet(newevents));
        timeline.redraw();

        this.setState({
            ...this.state,
            proptimeline: timeline,
            propevents: newevents
        });

    }

    modifyTimeline(info)
    {
        const timeline = this.state.proptimeline;
        var events = this.state.propevents;

        for(let i=0; i<events.length; i++)
        {
            if(events[i].id == info.id)
            {
                events.splice(i,1);
                break;
            }
        }

        let datai = new Date(info.start);
        let mesei = datai.getMonth()+1;
        let annoi = datai.getFullYear();
        let giornoi = datai.getDate();
        datai = annoi + '-' + mesei + '-' + giornoi;
        let dataf = new Date(info.end);
        let mesef = dataf.getMonth()+1;
        let annof = dataf.getFullYear();
        let giornof = dataf.getDate();
        dataf = annof + '-' + mesef + '-' + giornof;

        if(dataf == datai)
        {
            if(info.stato == 'Nessun incaricato')
            {
                events.push({id: parseInt(info.id), content: info.name, start: datai, style: "background-color: red;" });
            }
            else if(info.stato == "Proposta da approvare")
            {
                events.push({id: parseInt(info.id), content: info.name, start: datai, style: "background-color: blue;" });
            }
            else if(info.stato == "Da assegnare al manutentore")
            {
                events.push({id: parseInt(info.id), content: info.name, start: datai, style: "background-color: orange;" });
            }
            else if(info.stato == "Inviato al manutentore")
            {
                events.push({id: parseInt(info.id), content: info.name, start: datai, style: "background-color: pink;" });
            }
            else if(info.stato == "In carico al manutentore")
            {
                events.push({id: parseInt(info.id), content: info.name, start: datai, style: "background-color: pink;" });
            }
            else if(info.stato == "In lavorazione")
            {
                events.push({id: parseInt(info.id), content: info.name, start: datai, style: "background-color: pink;" });
            }
            else if(info.stato == "Sospeso")
            {
                events.push({id: parseInt(info.id), content: info.name, start: datai, style: "background-color: orange;" });
            }
            else if(info.stato == "Chiuso, da convalidare")
            {
                events.push({id: parseInt(info.id), content: info.name, start: datai, style: "background-color: green;" });
            }
            else if(info.stato == "Convalida negata")
            {
                events.push({id: parseInt(info.id), content: info.name, start: datai, style: "background-color: red;" });
            }
            else if(info.stato == "Convalidato")
            {
                events.push({id: parseInt(info.id), content: info.name, start: datai, style: "background-color: darkgray;" });
            }
            else if(info.stato == "Annullato")
            {
                events.push({id: parseInt(info.id), content: info.name, start: datai, style: "background-color: darkgray;" });
            }
        }
        else{
            if(info.stato == 'Nessun incaricato')
            {
                events.push({id: parseInt(info.id), content: info.name, start: datai, end: dataf, type: "range", style: "background-color: red;" });
            }
            else if(info.stato == "Proposta da approvare")
            {
                events.push({id: parseInt(info.id), content: info.name, start: datai, end: dataf, type: "range", style: "background-color: blue;" });
            }
            else if(info.stato == "Da assegnare al manutentore")
            {
                events.push({id: parseInt(info.id), content: info.name, start: datai, end: dataf, type: "range", style: "background-color: orange;" });
            }
            else if(info.stato == "Inviato al manutentore")
            {
                events.push({id: parseInt(info.id), content: info.name, start: datai, end: dataf, type: "range", style: "background-color: pink;" });
            }
            else if(info.stato == "In carico al manutentore")
            {
                events.push({id: parseInt(info.id), content: info.name, start: datai, end: dataf, type: "range", style: "background-color: pink;" });
            }
            else if(info.stato == "In lavorazione")
            {
                events.push({id: parseInt(info.id), content: info.name, start: datai, end: dataf, type: "range", style: "background-color: pink;" });
            }
            else if(info.stato == "Sospeso")
            {
                events.push({id: parseInt(info.id), content: info.name, start: datai, end: dataf, type: "range", style: "background-color: orange;" });
            }
            else if(info.stato == "Chiuso, da convalidare")
            {
                events.push({id: parseInt(info.id), content: info.name, start: datai, end: dataf, type: "range", style: "background-color: green;" });
            }
            else if(info.stato == "Convalida negata")
            {
                events.push({id: parseInt(info.id), content: info.name, start: datai, end: dataf, type: "range", style: "background-color: red;" });
            }
            else if(info.stato == "Convalidato")
            {
                events.push({id: parseInt(info.id), content: info.name, start: datai, end: dataf, type: "range", style: "background-color: darkgray;" });
            }
            else if(info.stato == "Annullato")
            {
                events.push({id: parseInt(info.id), content: info.name, start: datai, end: dataf, type: "range", style: "background-color: darkgray;" });
            }
        }

        timeline.setItems(new DataSet(events));
        timeline.redraw();

        this.setState({
            ...this.state,
            proptimeline: timeline,
            propevents: events
        });

        
    }

    render()
    {
        return(<></>);
    }

            
}

export default TimelineComponent;