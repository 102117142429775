import './App.css';
import { IfcViewerAPI } from 'web-ifc-viewer';
import { Backdrop, CircularProgress, IconButton} from '@material-ui/core';
import React,{useState,useEffect} from 'react';
import Axios from 'axios'
import Dropzone from 'react-dropzone';
import BcfDialog from './components/BcfDialog';
import PropWindow from './components/PropWindow';
import CalendarWindow from './components/CalendarWindow';
import TimelineComponent from './components/TimelineComponent';
import InfoWindow from './components/InfoWindow';
import { FloatButton } from 'antd';
//import { IFCBEAM,IFCBEARING,IFCCHIMNEY,IFCCOLUMN,IFCCOVERING,IFCCURTAINWALL,IFCDEEPFOUNDATION,IFCDOOR,IFCFOOTING,IFCMEMBER,IFCPLATE,IFCRAILING,IFCRAMP,IFCRAMPFLIGHT,IFCROOF,IFCSHADINGDEVICE,IFCSLAB,IFCSTAIR,IFCSTAIRFLIGHT,IFCWALL,IFCWINDOW } from 'web-ifc';
import { MeshLambertMaterial } from "three";
import 'bootstrap/dist/css/bootstrap.css';
import logo from './logo.png';


//Icons
import FolderOpenOutlinedIcon from '@material-ui/icons/FolderOpenOutlined';
import CropIcon from '@material-ui/icons/Crop';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import CalendarTodayOutlined from '@material-ui/icons/CalendarTodayOutlined';
import AddIcon from '@material-ui/icons/Add';
import EyeIcon from '@material-ui/icons/Visibility';
import NotEyeIcon from '@material-ui/icons/VisibilityOff';
import BrightIcon from '@material-ui/icons/Brightness5';
import ZoomIcon from '@material-ui/icons/ZoomOutMap';
import FilterIcon from '@material-ui/icons/FilterList';
import ControlCameraIcon from '@material-ui/icons/ControlCameraOutlined';


class App extends React.Component {

    state = {
        propsItem: null,
        propTranslucent: false,
        propFilter: false,
        propsFilter: null,
        infoWindowOpen: false,
        calendarWindowOpen: false,
        propWindowOpen: false,
        bcfDialogOpen: false,
        loaded: false,
        loading_ifc: false
    };

    constructor(props) {
        super(props);
        this.dropzoneRef = React.createRef();
        this.timelineRef = React.createRef();
    }

    componentDidMount() {
        const container = document.getElementById('viewer-container');
        const viewer = new IfcViewerAPI({container});
        viewer.addAxes();
        viewer.addGrid();
        viewer.IFC.setWasmPath('../../');

        this.viewer = viewer;

        window.onmousemove = viewer.IFC.prePickIfcItem;
        window.ondblclick = viewer.addClippingPlane;
        window.ondrag = null;
        var start = 0;
        window.onmousedown = (event) => {start = new Date();}
        window.onclick = async (event) => {
            try{
                var cur = new Date();
                if(cur-start > 190)
                    return;
                if(!(event.target instanceof HTMLCanvasElement))
                    return;
                if(event.ctrlKey && this.state.propsItem!=null)
                {
                    const {modelID, id} = await viewer.IFC.pickIfcItem(false);
                    if(modelID!=null && id!=null)
                    {
                        var items = this.state.propsItem;
                        var arr = [];
                        for(var i = 0; i<items.length; i++)
                            arr.push(items[i].expressID);
                        arr.push(id);
                        await viewer.IFC.pickIfcItemsByID(modelID,arr,false);
                        const props = await viewer.IFC.getProperties(modelID, id, true, false); 
                        items.push(props);                       
                        //console.log(props);
                        this.setState({
                            ...this.state,
                            propsItem: items
                        });
                    }
                    else
                    {
                        this.setState({
                            ...this.state
                        });
                    }
                }
                else
                {
                    const {modelID, id} = await viewer.IFC.pickIfcItem(true);
                    if(modelID!=null && id!=null)
                    {
                        const props = await viewer.IFC.getProperties(modelID, id, true, false);
                        //console.log(props);
                        this.setState({
                            ...this.state,
                            propsItem: [props]
                        });
                    }
                    else
                    {
                        this.setState({
                            ...this.state
                        });
                    }
                } 
            }
            catch(error)
            {
                /*this.setState({
                    ...this.state
                });*/
            }
        }

        window.onkeydown = (event) => {
            if(event.code === 'KeyC') {
                viewer.IFC.unpickIfcItems();
                viewer.IFC.unHighlightIfcItems();
                this.setState({
                    ...this.state,
                    propsItem: null
                });
            }
        }

        this.onDrop('21032-HUBITA-ARC-001.ifc');
    }


    /*saveItems = async (filename) => {
        var elementi = [IFCBEAM,IFCBEARING,IFCCHIMNEY,IFCCOLUMN,IFCCOVERING,IFCCURTAINWALL,IFCDEEPFOUNDATION,IFCDOOR,IFCFOOTING,IFCMEMBER,IFCPLATE,IFCRAILING,IFCRAMP,IFCRAMPFLIGHT,IFCROOF,IFCSHADINGDEVICE,IFCSLAB,IFCSTAIR,IFCSTAIRFLIGHT,IFCWALL,IFCWINDOW];
        for(var i=0; i<elementi.length; i++)
        {
            var allElements = await this.viewer.IFC.loader.ifcManager.getAllItemsOfType(0,elementi[i],false);
            for(let j=0; j<allElements.length; j++)
            {            
                var proprieta = await this.viewer.IFC.getProperties(0,allElements[j], true, false);
                var psets = proprieta.psets;
                var abs = null;
                var qto = null;
                var pro = null;
                for(let k=(psets.length-1); k>=0; k--)
                {
                    if(psets[k].Name.value=='Pset_ABS')
                    {
                        abs = psets[k].expressID;
                    }
                    if(psets[k].Name.value=='Pset_QTO')
                    {
                        qto = psets[k].expressID;
                    }
                    if(psets[k].Name.value=='Pset_PRO')
                    {
                        pro = psets[k].expressID;
                    }
                }
    
                var haveElements = {file: filename, express_id: proprieta.expressID, name: proprieta.Name.value, qto_express_id: qto, pro_express_id: pro, abs_express_id: abs};
                var action = 'insertItem';
                Axios.post("http://localhost/api/server/server.php",{action,haveElements});
    
    
            }
        }

    }*/




    onDrop = async (file) => {

        this.setState({ loading_ifc: true })
        await this.viewer.IFC.loadIfcUrl(file, true);
        this.setState({ loaded: true, loading_ifc: false })
    };

    handleToggleClipping = () => {
        this.viewer.clipper.active = !this.viewer.clipper.active;
    };

    handleClickOpen = () => {
        this.dropzoneRef.current.open();
    };

    handleOpenBcfDialog = () => {
        this.setState({
            ...this.state,
            bcfDialogOpen: true
        });
    };

    handleCloseBcfDialog = () => {
        this.setState({
            ...this.state,
            bcfDialogOpen: false
        });
    };

    handleOpenPropWindow = () => {
        this.setState({
            ...this.state,
            propWindowOpen: true
        });
    };

    handleClosePropWindow = () => {
        this.setState({
            ...this.state,
            propWindowOpen: false
        });
    };

    handleOpenCalendar = () => {
        this.setState({
            ...this.state,
            calendarWindowOpen: true
        });
    };

    handleCloseCalendar = () => {
        this.setState({
            ...this.state,
            calendarWindowOpen: false
        });
    };

    handleSubmitCalendar = () => {
        var interventionselection = document.getElementById("interventionselection").textContent;
        var interventionname = document.getElementById("interventionname").value;
        var interventiontype = document.getElementById("interventiontype");
        interventiontype = interventiontype.options[interventiontype.selectedIndex].value;
        var interventiondescription = document.getElementById("interventiondescription").value;
        var interventionworker = document.getElementById("interventionworker");
        interventionworker = interventionworker.options[interventionworker.selectedIndex].value;
        var interventionstart = document.getElementById("interventionstart").value;
        var interventionend = document.getElementById("interventionend").value;
        var interventionstatus = document.getElementById("interventionstatus");
        interventionstatus = interventionstatus.options[interventionstatus.selectedIndex].value;
        if((interventionstart==null || interventionstart=='') || (interventionend==null || interventionend=='') || (interventionstatus==null || interventionstatus=='') || (interventionend<interventionstart))
        {
            return;
        }
        var action = "createEvent";
        Axios.post("https://api.rimond.tecnohub.eu/api/server/server.php",{action,interventionname,interventiontype,interventiondescription,interventionworker,interventionstart,interventionend,interventionstatus}).then(
            (info) => {
                let newid = info.data.insertId
                if(newid==null)
                {
                    return;
                }

                var interventionattachment: any =  document.getElementById("interventionattachment");
                var file = interventionattachment.files[0];
                if(file!=null)
                {
                    var formData = new FormData();
                    // Add the File object to the formdata
                    formData.append("file", file);
                    // Add your data...
                    formData.append("data", newid);
                    formData.append("action", "insertFile");
                    
                    Axios.post("https://api.rimond.tecnohub.eu/api/server/file.php", formData, {
                        headers: {
                          'Content-Type': 'multipart/form-data'
                        }
                      }).then((info) => {console.log(info);});
                }

                if(interventionselection=='YES')
                {
                    var ids = [];
                    var items = this.state.propsItem;
                    for(var i=0; i<items.length; i++)
                    {
                        ids.push(items[i].expressID);
                    }
                    var action = 'partOf';
                    Axios.post("https://api.rimond.tecnohub.eu/api/server/server.php",{action,ids,newid}).then((info) => {console.log(info);});
                }

                this.timelineRef.current.updateTimeline({id: newid,name: interventionname,start: interventionstart,end: interventionend, stato: interventionstatus});
                this.handleCloseCalendar();  
            }
        );
        
    };

    handleOpenInfo = (id) => {
        if(id==null)
            return;

            var action = "getById";
            Axios.post("https://api.rimond.tecnohub.eu/api/server/server.php",{action,id}).then((data)=>{
                if(data!=null){
                    if(data.data.length==1)
                    {
                        let idevento = document.getElementById("idevento");
                        let nomeevento = document.getElementById("nomeevento");
                        let tipoevento = document.getElementById("tipoevento");
                        let descrizioneevento = document.getElementById("descrizioneevento");
                        let menuevento = document.getElementById("manuevento");
                        let inizioevento = document.getElementById("inizioevento");
                        let fineevento = document.getElementById("fineevento");
                        let statoevento = document.getElementById("statoevento");
        
                        let datai = new Date(data.data[0].inizio);
                        let mesei = datai.getMonth()+1;
                        let annoi = datai.getFullYear();
                        let giornoi = datai.getDate();
                        if(giornoi>=1 && giornoi<=9)
                            giornoi = '0'+giornoi;
                        if(mesei>=1 && mesei<=9)
                            mesei = '0' + mesei;
                        datai = annoi + '-' + mesei + '-' + giornoi;
                        let dataf = new Date(data.data[0].fine);
                        let mesef = dataf.getMonth()+1;
                        let annof = dataf.getFullYear();
                        let giornof = dataf.getDate();
                        if(giornof>=1 && giornof<=9)
                        giornof = '0'+giornof;
                        if(mesef>=1 && mesef<=9)
                        mesef = '0' + mesef;
                        dataf = annof + '-' + mesef + '-' + giornof;
        
                        idevento.value=data.data[0].id;
                        nomeevento.value=data.data[0].nome;
                        for(var i=0 ; i < tipoevento.options.length; i++)
                        {
                            if(tipoevento.options[i].value == data.data[0].tipo)
                                tipoevento.options[i].selected = true;
                        }
                        descrizioneevento.value=data.data[0].descrizione;
                        for(var i=0 ; i < menuevento.options.length; i++)
                        {
                            if(menuevento.options[i].value == data.data[0].manutentore)
                                menuevento.options[i].selected = true;
                        }
                        inizioevento.value=datai;
                        fineevento.value=dataf;
                        for(var i=0 ; i < statoevento.options.length; i++)
                        {
                            if(statoevento.options[i].value == data.data[0].stato)
                                statoevento.options[i].selected = true;
                        }
                       
                    }
                    
                }
            });

        this.setState({
            ...this.state,
            infoWindowOpen: true
        });

    };

    handleEventsItems = async (id) => {
        var loaded = this.state.loaded;
        if(loaded)
        {
            if(id==null)
            return;

            var action = "getItemsByEvent";
            var ritorno = await Axios.post("https://api.rimond.tecnohub.eu/api/server/server.php",{action,id}).then( async (data)=>{
                if(data!=null){
                    if(data.data!=null && data.data!='' && data.data!='0 results')
                    {
                        var arr = [];
                        for(var i = 0; i<data.data.length; i++)
                        {
                            arr.push(data.data[i].express_id);
                        }
                        return arr;
                    }
                    return null;
                }
                return null;
            });

            if(ritorno!=null)
            {
                var items = [];
                for(var i=0; i<ritorno.length; i++)
                {
                    var props = await this.viewer.IFC.getProperties(0, ritorno[i], true, false);
                    items.push(props); 
                }


                await this.viewer.IFC.pickIfcItemsByID(0,ritorno,false);

                this.setState({
                    ...this.state,
                    propsItem: items
                });
            }
        }

    };


    handleCloseInfo = () => {
        this.setState({
            ...this.state,
            infoWindowOpen: false
        });
    };

    handleSubmitInfo = () =>
    {
        var interventionid = document.getElementById("idevento").value;
        var interventionname = document.getElementById("nomeevento").value;
        var interventiontype = document.getElementById("tipoevento");
        interventiontype = interventiontype.options[interventiontype.selectedIndex].value;
        var interventiondescription = document.getElementById("descrizioneevento").value;
        var interventionworker = document.getElementById("manuevento");
        interventionworker = interventionworker.options[interventionworker.selectedIndex].value;
        var interventionstart = document.getElementById("inizioevento").value;
        var interventionend = document.getElementById("fineevento").value;
        var interventionstatus = document.getElementById("statoevento");
        interventionstatus = interventionstatus.options[interventionstatus.selectedIndex].value;

        if((interventionstart==null || interventionstart=='') || (interventionend==null || interventionend=='') || (interventionstatus==null || interventionstatus=='') || (interventionend<interventionstart))
        {
            return;
        }
        var action = "updateEvent";
        Axios.post("https://api.rimond.tecnohub.eu/api/server/server.php",{action,interventionid,interventionname,interventiontype,interventiondescription,interventionworker,interventionstart,interventionend,interventionstatus})
        this.timelineRef.current.modifyTimeline({id: interventionid,name: interventionname,start: interventionstart,end: interventionend, stato: interventionstatus});
        this.handleCloseInfo();
    };

    handleOpenViewpoint = (viewpoint) => {
        this.viewer.currentViewpoint = viewpoint;
    };

    handleHideElement = () =>
    {
        const item = this.state.propsItem;
        if(item!=null)
        {
            for(var i=0; i<item.length; i++)
            {
                this.viewer.IFC.hideItems(0,[item[i].expressID]);
                this.viewer.IFC.unpickIfcItems();
                this.viewer.IFC.unHighlightIfcItems();
                this.setState({
                    ...this.state,
                    propsItem: null
                });
            }

        }
    };

    
    handleUnhideAllElements = () =>
    {
        const loaded = this.state.loaded;
        if(loaded)
        {
            this.viewer.IFC.showAllItems(0);
        }
    };

    handleTranslucet = () =>
    {
        const loaded = this.state.loaded;
        if(loaded)
        {
            const translucent = this.state.propTranslucent;
            if(!translucent)
            {
                this.viewer.IFC.setModelTranslucency(0,true);
                this.setState({
                    ...this.state,
                    propTranslucent: true
                });
            }
            else{
                this.viewer.IFC.setModelTranslucency(0,false);
                this.setState({
                    ...this.state,
                    propTranslucent: false
                });
            }
            
        }
    };

    handleResetCamera = () =>
    {
        this.viewer.context.getIfcCamera().goToHomeView();
    };

    handleSearchFilter = async() =>
    {
        const loaded = this.state.loaded;
        if(loaded)
        {
            const filter = this.state.propFilter;
            if(!filter)
            {
                var action = 'getNonAbs';
                var proprieta = await Axios.post("https://api.rimond.tecnohub.eu/api/server/server.php",{action}).then(
                    async (data) => {
                        var array = [];
                        for(var i=0; i<data.data.length; i++)
                        {
                            array.push(data.data[i].express_id);
                        }

                        var materiale = new MeshLambertMaterial({
                            transparent: false,
                            opacity: 1,
                            color: 0x000000,
                            depthTest: false,
                          });
                
                        var proprieta = await this.viewer.IFC.loader.ifcManager.createSubset({
                            modelID: 0,
                            ids: array,
                            material: materiale,
                            scene: this.viewer.context.getScene(),
                            removePrevious: true
                        });
                        return proprieta;
                    }
                );
                //console.log(proprieta);
                this.setState({
                    ...this.state,
                    propFilter: true,
                    propsFilter: proprieta
                });
            }
            else
            {
                await this.viewer.IFC.loader.ifcManager.removeSubset(0,this.state.propsFilter.parent,this.state.propsFilter.material);
                this.setState({
                    ...this.state,
                    propFilter: false
                });
            }

        }

    }
    handleFirstPersonMode = () =>
    {
        this.viewer.IFC.context.ifcCamera.setNavigationMode(1);
    };

    render() {

        return (
          <>
            {/*  <BcfDialog
                open={this.state.bcfDialogOpen}
                onClose={this.handleCloseBcfDialog}
                onOpenViewpoint={this.handleOpenViewpoint}
            />*/}
              <PropWindow
                open={this.state.propWindowOpen}
                onClose={this.handleClosePropWindow}
                onOpenViewpoint={this.handleOpenViewpoint}
                getState = {this.state.propsItem}
                getViewer = {this.viewer}
              />
              <CalendarWindow
                open={this.state.calendarWindowOpen}
                onSubmit={this.handleSubmitCalendar}
                onClose={this.handleCloseCalendar}
                onOpenViewpoint={this.handleOpenViewpoint}
                getState = {this.state.propsItem}
              />
              <InfoWindow 
                open={this.state.infoWindowOpen}
                onSubmit={this.handleSubmitInfo}
                onClose={this.handleCloseInfo}
                onOpenViewpoint={this.handleOpenViewpoint}
                onDelete={this.timelineRef}
              />
              <FloatButton.Group
                icon={<AddIcon />}
                style={{ top:20, right:20,position:'fixed'}}
                shape='circle'
                trigger='click'
              >
                <FloatButton icon={<EyeIcon />} shape='circle' tooltip='unhide all items'  onClick={this.handleUnhideAllElements} />
                <FloatButton id='bottoneicona' icon={<NotEyeIcon />} shape='circle' tooltip='hide item' onClick={this.handleHideElement} />
                <FloatButton icon={<BrightIcon />} shape='circle' tooltip='solid/x-ray' onClick={this.handleTranslucet} />
                <FloatButton icon={<ZoomIcon />} shape='circle' tooltip='reset view to starting point' onClick={this.handleResetCamera} />
                <FloatButton icon={<FilterIcon />} shape='circle' tooltip='Search&Filter by ABS Code' onClick={this.handleSearchFilter} />
                <FloatButton icon={<ControlCameraIcon />} shape='circle' tooltip='First Person Navigation Mode' onClick={this.handleFirstPersonMode} />
              </FloatButton.Group>
              <div style={{ display: 'flex', flexDirection: 'row', height: '100vh' }}>
                  <aside style={{ width: 50 }}>
                     {/* <IconButton onClick={this.handleClickOpen}>
                          <FolderOpenOutlinedIcon />
                         </IconButton>*/ }
                      {/*<IconButton onClick={this.handleToggleClipping}>
                          <CropIcon />
                        </IconButton>*/}
                    <center><img src={logo} alt="Logo" width="20px" height="100px"/></center>
                    { /*<IconButton onClick={this.handleOpenBcfDialog}>
                          <FeedbackOutlinedIcon />
                    </IconButton>*/
                     <IconButton onClick={this.handleOpenPropWindow}>
                          <InfoIcon />
                    </IconButton>
                    }
                    <IconButton onClick={this.handleOpenCalendar}>
                    <CalendarTodayOutlined />
                    </IconButton>
                    
                  </aside>
                {/*  <Dropzone accept=".ifc, .ifcXML, .ifcZIP" ref={this.dropzoneRef} onDrop={this.onDrop}>
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps({ className: 'dropzone' })}>
                            <input {...getInputProps()} />
                        </div>
                      )} 
                  </Dropzone> */}
                  <div style={{ flex: '1 1 auto', minWidth: 0 }}>
                      <div id='viewer-container' style={{ position: 'relative', height: '100%', width: '100%' }} />
                  </div>
              </div>
              <Backdrop
                style={{
                    zIndex: 100,
                    display: "flex",
                    alignItems: "center",
                    alignContent: "center"
                }}
                open={this.state.loading_ifc}
              >
                  <CircularProgress/>
              </Backdrop>
              <TimelineComponent 
                ref={this.timelineRef}
                onOpen={this.handleOpenInfo}  
                onSingleClick={this.handleEventsItems}
                />
          </>
        );
    }
}

export default App;
