import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle,Typography } from '@material-ui/core';

const useStyles = makeStyles({
    root: {
        maxWidth: 345,
    },
    media: {
        height: 140,
    },
});

export default function CalendarWindow(props) {

    const { onOpenViewpoint, getState, ...rest } = props;

    return (
      <Dialog maxWidth='md' fullWidth disableBackdropClick {...rest}>
          <DialogTitle>New Event</DialogTitle>
          <DialogContent>
                <Box
                  height={380}
                  display='flex'
                  alignItems="center"
                  justifyContent="center"
                  border={"solid 4px"}
                  borderColor={"#FF0000"}
                  borderRadius={20}
                >
                <Typography> 
            <div className="row g-2 align-items-center form-control-sm"> 
              <div className="col-auto">Items Selected:</div> 
              <div className="col-auto"> 
                <div id="interventionselection"> 
                  {getState == null ? "NOT SELECTED" : "YES"} 
                </div> 
              </div> 
            </div> 
            <div className="row g-2 align-items-center form-control-sm"> 
              <div className="col-auto">Intervention name:</div> 
              <div className="col-auto"> 
                <input 
                  className="form-control form-control-sm" 
                  type="text" 
                  id="interventionname" 
                /> 
              </div> 
            </div> 
            <div className="row g-2 align-items-center form-control-sm"> 
              <div className="col-auto">Intervention urgency:</div> 
              <div className="col-auto"> 
                <select 
                  className="form-select form-select-sm" 
                  id="interventiontype" 
                  name="interventiontype" 
                > 
                  <option value=""> 
                   
                  </option>  
                  <option value="Bassa urgenza"> 
                    Bassa urgenza 
                  </option> 
                  <option value="Priorità alta"> 
                    Priorità alta 
                  </option> 
                  <option value="Priorità media"> 
                    Priorità media 
                  </option> 
                </select> 
              </div> 
            </div> 
            <div className="row g-2 align-items-center form-control-sm"> 
              <div className="col-auto">Intervention description:</div> 
              <div className="col-auto"> 
                <input 
                  className="form-control form-control-sm" 
                  type="text" 
                  id="interventiondescription" 
                /> 
              </div> 
            </div> 
            <div className="row g-2 align-items-center form-control-sm"> 
              <div className="col-auto">Maintenance worker:</div> 
              <div className="col-auto"> 
                <select 
                className="form-select form-select-sm" 
                id="interventionworker" 
                name="interventionworker" 
                > 
                  <option value=""> 
                   
                  </option>  
                  <option value="Benedetto Olivieri"> 
                    Benedetto Olivieri 
                  </option> 
                </select> 
              </div> 
            </div> 
            <div className="row g-2 align-items-center form-control-sm"> 
              <div className="col-auto">Intervention start date:</div> 
              <div className="col-auto"> 
                <input 
                  className="form-control form-control-sm" 
                  type="date" 
                  id="interventionstart" 
                /> 
              </div> 
            </div> 
            <div className="row g-2 align-items-center form-control-sm"> 
              <div className="col-auto">Intervention end date:</div> 
              <div className="col-auto"> 
                <input 
                  className="form-control form-control-sm" 
                  type="date" 
                  id="interventionend" 
                /> 
              </div> 
            </div> 
            <div className="row g-2 align-items-center form-control-sm"> 
              <div className="col-auto">Intervention status:</div> 
              <div className="col-auto"> 
                  <select 
                    className="form-select form-select-sm" 
                    id="interventionstatus" 
                    name="interventionstatus" 
                  > 
                  <option value=""> 
 
                  </option>  
                  <option value="Nessun incaricato"> 
                    Nessun incaricato 
                  </option>
                  <option value="Proposta da approvare"> 
                    Proposta da approvare 
                  </option> 
                  <option value="Da assegnare al manutentore"> 
                    Da assegnare al manutentore 
                  </option> 
                  <option value="Inviato al manutentore"> 
                    Inviato al manutentore 
                  </option> 
                  <option value="In carico al manutentore"> 
                    In carico al manutentore 
                  </option> 
                  <option value="In lavorazione">In lavorazione</option> 
                  <option value="Sospeso">Sospeso</option> 
                  <option value="Chiuso, da convalidare"> 
                    Chiuso, da convalidare 
                  </option> 
                  <option value="Convalida negata">Convalida negata</option> 
                  <option value="Convalidato">Convalidato</option> 
                  <option value="Annullato">Annullato</option> 
                </select> 
              </div> 
            </div> 
            <div className="row g-2 align-items-center form-control-sm"> 
              <div className="col-auto">Add Attachment:</div> 
              <div className="col-auto"> 
                <input 
                  className="form-control form-control-sm btn btn-outline-primary btn-sm" 
                  type="file" 
                  id="interventionattachment" 
                  accept="application/pdf" 
                /> 
              </div> 
            </div> 
          </Typography>
                </Box>
          </DialogContent>
          <DialogActions>
          <Button onClick={props.onSubmit}>
                  Submit
              </Button>
              <Button onClick={props.onClose}>
                  Close
              </Button>
          </DialogActions>
      </Dialog>
    );
}


function TopicCard(props){

    const classes = useStyles();

    const { topic, onOpenViewpoint } = props;

    const defaultViewpoint = topic?.markup?.viewpoints?.length > 0 ? topic.markup.viewpoints[0] : null;

    const [defaultViewpointUrl, setDefaultViewpointUrl] = useState("");

    const handleClick = () => {
        if(topic.viewpoints.length > 0) {
            onOpenViewpoint(topic.viewpoints[0]);
        }
    }

    useEffect(() => {
        const getViewpoint = async () => {
            if(defaultViewpoint){
                const data = await topic.getViewpointSnapshot(defaultViewpoint);
                const url = window.URL.createObjectURL(data);
                setDefaultViewpointUrl(url);
            }
        }
        getViewpoint()
    }, [defaultViewpoint, topic]);

    return (
      <Card className={classes.root}>
          <CardActionArea onClick={handleClick}>
              <CardMedia
                className={classes.media}
                image={defaultViewpointUrl}
                title={topic.markup.topic.title}
              />
              <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                      {topic.markup.topic.title}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                      {topic.markup.topic.description}
                  </Typography>
              </CardContent>
          </CardActionArea>
          {/*<CardActions>
              <Button size="small" color="primary">
                  Open
              </Button>
              <Button size="small" color="primary">
                  Learn More
              </Button>
          </CardActions>*/}
      </Card>
    )
}