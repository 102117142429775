import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle,Typography,CircularProgress } from '@material-ui/core';

const useStyles = makeStyles({
    root: {
        maxWidth: 345,
    },
    media: {
        height: 140,
    },
});

export default function PropWindow(props) {

    const { onOpenViewpoint, getState, getViewer, ...rest } = props;

    const showMoreABS = async (abs) =>
    {
        var bottone = document.getElementById("buttoninfoabs"+abs.expressID);
        if(bottone.value == "show more")
        {
            var contenitore = document.getElementById("infoabs"+abs.expressID);
            var str = '';
            for(var i=0; i<abs.HasProperties.length;i++)
            {
                var proprieta = await getViewer.IFC.getProperties(0, abs.HasProperties[i].value, true, false);
                str += proprieta.Name.value + ': ' + proprieta.NominalValue.value + '<br>';

            }
            contenitore.innerHTML='<center>' + str + '<center>';
            bottone.value = "close";
        }
        else if(bottone.value == "close")
        {
            var contenitore = document.getElementById("infoabs"+abs.expressID);
            contenitore.innerText="";
            bottone.value = "show more";
        }
    }

    const showMoreQTO = async (qto) =>
    {
        var bottone = document.getElementById("buttoninfoqto"+qto.expressID);
        if(bottone.value == "show more")
        {
            var contenitore = document.getElementById("infoqto"+qto.expressID);
            var str = '';
            for(var i=0; i<qto.HasProperties.length;i++)
            {
                var proprieta = await getViewer.IFC.getProperties(0, qto.HasProperties[i].value, true, false);
                str += proprieta.Name.value + ': ' + proprieta.NominalValue.value + '<br>';

            }
            contenitore.innerHTML='<center>' + str + '<center>';
            bottone.value = "close";
        }
        else if(bottone.value == "close")
        {
            var contenitore = document.getElementById("infoqto"+qto.expressID);
            contenitore.innerText="";
            bottone.value = "show more";
        }
    }

    const showMorePRO = async (pro) =>
    {
        var bottone = document.getElementById("buttoninfopro"+pro.expressID);
        if(bottone.value == "show more")
        {
            var contenitore = document.getElementById("infopro"+pro.expressID);
            var str = '';
            for(var i=0; i<pro.HasProperties.length;i++)
            {
                var proprieta = await getViewer.IFC.getProperties(0, pro.HasProperties[i].value, true, false);
                str += proprieta.Name.value + ': ' + proprieta.NominalValue.value + '<br>';

            }
            contenitore.innerHTML='<center>' + str + '<center>';
            bottone.value = "close";
        }
        else if(bottone.value == "close")
        {
            var contenitore = document.getElementById("infopro"+pro.expressID);
            contenitore.innerText="";
            bottone.value = "show more";
        }
    }

    const printPorp = (stato) => 
    { 
        if(stato==null) 
        { 
            return <center><Typography variant="h6" >ITEM NOT SELECTED</Typography></center>
        } 
        else 
        { 
           //console.log(stato[0]); 
            var stringa = [];
            var absapp = [];
            var qtoapp = []; 
            var proapp = [];  
            for(let i=0; i<stato.length; i++){ 
                var psets = stato[i].psets; 
                var abs = null; 
                var qto = null; 
                var pro = null;
                for(let k=(psets.length-1); k>=0; k--) 
                { 
                    if(psets[k].Name.value=='Pset_ABS') 
                    { 
                        abs = psets[k].expressID;
                        absapp.push(psets[k]);
                    } 
                    if(psets[k].Name.value=='Pset_QTO') 
                    { 
                        qto = psets[k].expressID;
                        qtoapp.push(psets[k]); 
                    } 
                    if(psets[k].Name.value=='Pset_PRO') 
                    { 
                        pro = psets[k].expressID;
                        proapp.push(psets[k]); 
                    } 
                }
                if((absapp.length-1)!=i)
                absapp.push('');
                if((proapp.length-1)!=i)
                proapp.push('');
                if((qtoapp.length-1)!=i)
                qtoapp.push('');

                stringa[i] = 
                <>
                <dl className="row">
                    <dt className="col-sm-3">ITEM NAME: </dt>
                    <dd className="col-sm-9">{stato[i].Name.value}</dd>
                    {abs && (<><dt className="col-sm-3">ITEM ABS: </dt><dd className="col-sm-9"><input className="form-control form-control-sm btn btn-outline-primary btn-sm" id={"buttoninfoabs"+abs} type='button' value='show more' onClick={event => {showMoreABS(absapp[i]);}}/></dd><dd className="col-sm-12" id={"infoabs" + abs}></dd></>)}
                    {qto && (<><dt className="col-sm-3">ITEM QTO: </dt><dd className="col-sm-9"><input className="form-control form-control-sm btn btn-outline-primary btn-sm" id={"buttoninfoqto"+qto} type='button' value='show more' onClick={event => {showMoreQTO(qtoapp[i]);}}/></dd><dd className="col-sm-12" id={"infoqto" + qto}></dd></>)}
                    {pro && (<><dt className="col-sm-3">ITEM PRO: </dt><dd className="col-sm-9"><input className="form-control form-control-sm btn btn-outline-primary btn-sm" id={"buttoninfopro"+pro} type='button' value='show more' onClick={event => {showMorePRO(proapp[i]);}}/><dd className="col-sm-12" id={"infopro" + pro}></dd></dd></>)}
                    </dl><hr></hr></>; 
            } 
            return <Typography variant="h6">{stringa}</Typography> 
        }
    } 

    const handleOpenViewpoint = (viewpoint) => {
        onOpenViewpoint(viewpoint);
        props.onClose()
    }

    return (
      <Dialog maxWidth='md' fullWidth disableBackdropClick {...rest}>
          <DialogTitle>Selected Item</DialogTitle>
          <DialogContent>
          <Box 
                  height={380} 
                  border={"solid 4px"} 
                  borderColor={"#FF0000"} 
                  borderRadius={20} 
                  overflow="auto" 
                  paddingLeft={3} 
                >
                   {
                    printPorp(getState,getViewer)
                   }
                </Box>
          </DialogContent>
          <DialogActions>
              <Button onClick={props.onClose}>
                  Close
              </Button>
          </DialogActions>
      </Dialog>
    );
}


function TopicCard(props){

    const classes = useStyles();

    const { topic, onOpenViewpoint } = props;

    const defaultViewpoint = topic?.markup?.viewpoints?.length > 0 ? topic.markup.viewpoints[0] : null;

    const [defaultViewpointUrl, setDefaultViewpointUrl] = useState("");

    const handleClick = () => {
        if(topic.viewpoints.length > 0) {
            onOpenViewpoint(topic.viewpoints[0]);
        }
    }

    useEffect(() => {
        const getViewpoint = async () => {
            if(defaultViewpoint){
                const data = await topic.getViewpointSnapshot(defaultViewpoint);
                const url = window.URL.createObjectURL(data);
                setDefaultViewpointUrl(url);
            }
        }
        getViewpoint()
    }, [defaultViewpoint, topic]);

    return (
      <Card className={classes.root}>
          <CardActionArea onClick={handleClick}>
              <CardMedia
                className={classes.media}
                image={defaultViewpointUrl}
                title={topic.markup.topic.title}
              />
              <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                      {topic.markup.topic.title}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                      {topic.markup.topic.description}
                  </Typography>
              </CardContent>
          </CardActionArea>
          {/*<CardActions>
              <Button size="small" color="primary">
                  Open
              </Button>
              <Button size="small" color="primary">
                  Learn More
              </Button>
          </CardActions>*/}
      </Card>
    )
}